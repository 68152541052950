export const remove = (state, id) => {
  const newobj = []
  state.filter(
    (o) => Object.entries(o).map(([key, value]) => {
      if (key === 'id' && value !== id) { console.log(key, value); newobj.push(o) }
      return o
    })

  )

  return newobj
}

export const insert = (old, payload) => {
  old.push(payload)
  return old
}

export const update = (old, data, id) => {
  let it = []
  if (old?.length > 0) {
    it = old.map((o) => {
      if (parseInt(Object.values(o)[0], 10) === parseInt(id, 10)) {
        const n = Object.assign(o, data)

        return n
      }
      return o
    })

    return it
  }

  return old
}

export const updateStudentContract = (old, data, id) => {
  let it = []
  if (old?.length > 0) {
    it = old.map((o) => {
      if (parseInt(Object.values(o)[1], 10) === parseInt(id, 10)) {
        const n = Object.assign(o, data)

        return n
      }
      return o
    })

    return it
  }

  return old
}

export const view = (old, id) => {
  const pdf = []

  old.map((o) => {
    if (parseInt(o.id, 10) === parseInt(id, 10)) {
      pdf.push(o)
    }
    return o
  })

  return pdf
}

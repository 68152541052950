import * as effects from '../effects'
import { takeEvery } from 'redux-saga/effects'

export function* getWatcherSaga() {
  yield takeEvery('API_METHOD_GET', effects.getEffectSaga)
}

export function* paginationWatcherSaga() {
  yield takeEvery('API_METHOD_PAGINATION', effects.paginationEffectSaga)
}

export function* getPdfWatcherSaga() {
  yield takeEvery('API_METHOD_GET_PDF', effects.getPdfEffectSaga)
}

export function* postWatcherSaga() {
  yield takeEvery('API_METHOD_POST', effects.postEffectSaga)
}

export function* resourcePostWatcherSaga() {
  yield takeEvery('RESOURCE_API_METHOD_POST', effects.resourcePostEffectSaga)
}

export function* putWatcherSaga() {
  yield takeEvery('API_METHOD_PUT', effects.putEffectSaga)
}

export function* deleteWatcherSaga() {
  yield takeEvery('API_METHOD_DELETE', effects.deleteEffectSaga)
}

export function* loginWatchSaga() {
  yield takeEvery('API_METHOD_POST_LOGIN', effects.login)
}

export function* viewWatcherSaga() {
  yield takeEvery('API_METHOD_VIEW', effects.viewEffectSaga)
}

export function* viewPeriodWatcherSaga() {
  yield takeEvery('API_METHOD_VIEW_PERIOD', effects.viewPeriodEffectSaga)
}

export function* putStudentContractWatcherSaga() {
  yield takeEvery(
    'API_METHOD_PUT_STUDENT_CONTRACT',
    effects.putStudentContractEffectSaga
  )
}

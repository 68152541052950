import * as api from '../../reducers/api/methods'

import { call, put, select } from 'redux-saga/effects'

export default function * getEffectSaga (action) {
  try {
    yield put({ type: action.flag }) // set status false

    const state = yield select()

    const jwt = state.app.auth.jwt

    const { data } = yield call(api.apiGet, action.path, jwt)

    yield put({ type: action.subtype, payload: data })
  } catch (e) {
    window.alert(e)
  }
}

export const IS_LOGGED = 'IS_LOGGED'

export const LOGOUT = 'LOGOUT'

export const LOGIN = 'LOGIN'

export const REGISTER_USER = 'REGISTER_USER'

export const ERROR_MSG = 'ERROR_MSG'

export const RESET_STATE = 'RESET_STATE'

import * as Utils from '../utils'

import { put, select } from 'redux-saga/effects'

export default function * viewEffectSaga (action) {
  try {
    yield put({ type: action.flag }) // set status false

    const state = yield select()

    const old = state[action?.state][action?.intercept]

    const data = Utils.view(old, action.id)

    yield put({ type: action.subtype, payload: Array.from(data) })
  } catch (e) {
    alert(e)
  }
}

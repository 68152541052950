import * as apiWatcher from './watcher'

import { put, all } from 'redux-saga/effects'

export function* testSaga() {
  console.log('Sagas is Okay!')
  yield put({ type: 'TEST' })
}

export function* rootSaga() {
  yield all([
    apiWatcher.getWatcherSaga(),
    apiWatcher.getPdfWatcherSaga(),
    apiWatcher.paginationWatcherSaga(),
    apiWatcher.viewWatcherSaga(),
    apiWatcher.viewPeriodWatcherSaga(),
    apiWatcher.postWatcherSaga(),
    apiWatcher.putWatcherSaga(),
    apiWatcher.deleteWatcherSaga(),
    apiWatcher.loginWatchSaga(),
    apiWatcher.resourcePostWatcherSaga(),
    apiWatcher.putStudentContractWatcherSaga()
    // add other watchers to the array
  ])
}

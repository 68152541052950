import * as api from '../../reducers/api/methods'

import * as Utils from '../utils'

import { call, put, select } from 'redux-saga/effects'

export default function * putEffectSaga (action) {
  try {
    yield put({ type: action.flag }) // set status false

    const state = yield select()

    const jwt = state.auth.jwt

    const { data } = yield call(
      api.apiUpdate,
      action.path,
      action.payload,
      jwt
    )

    if (parseInt(data.code, 10) === 1) {
      const old = state[action?.state][action?.intercept]

      const result = Utils.update(old, data.data, action.id)

      yield put({ type: action.subtype, payload: result })
    } else {
      return alert(data.msg)
    }
  } catch (e) {
    alert(e)
  }
}

import { combineReducers } from 'redux'

import { persistReducer } from 'redux-persist'

import reducers from './reducers'

import configs from './persistConfig'

export default combineReducers({
  app: persistReducer(configs, reducers),
})

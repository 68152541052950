import axios from 'axios'

import { SERVER_URL_API, TOKEN_KEY_PREFIX } from '../../consts'

export function apiGet(path, token) {
  return axios.request({
    method: 'get',
    url: `${SERVER_URL_API}${path}`,

    headers: {
      Authorization: TOKEN_KEY_PREFIX.concat(token)
    }
  })
}

export function apiPost(path, param, token) {
  return axios.request({
    method: 'post',
    url: `${SERVER_URL_API}${path}`,
    headers: {
      Authorization: TOKEN_KEY_PREFIX.concat(token)
    },
    data: param
  })
}

export function apiDelete(path, token) {
  return axios.request({
    method: 'delete',
    url: `${SERVER_URL_API}${path}`,

    headers: {
      Authorization: TOKEN_KEY_PREFIX.concat(token)
    }
  })
}

export const apiUpdate = async (path, param, token) => {
  return axios.request({
    method: 'put',
    url: `${SERVER_URL_API}${path}`,

    headers: {
      Authorization: TOKEN_KEY_PREFIX.concat(token)
    },
    data: param
  })
}


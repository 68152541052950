import * as api from '../../reducers/api/methods'

import { call, put } from 'redux-saga/effects'

// Login
export default function* login(action) {
  try {
    const { data } = yield call(api.apiPost, action.path, action.payload)

    console.log(action)

    if (parseInt(data.code, 10) === 1) {
      yield put({ type: action.subtype, payload: data })
    } else {
      yield put({
        type: 'ERROR_MSG',
        payload: 'Campo Usuário ou Senha: Inválido.'
      })
    }
  } catch (e) {
    alert(e)
  }
}

import initialState from './initialStates.js';
import * as auth from './auth/consts'
import * as year from './years/consts';




export default (state = initialState, action) => {
    const payloads = action.payload?.data?.token?.split('.')

    switch (action.type) {
        /** Auth */
        case auth.LOGIN:
            return { ...state, fetching: true, user: action.profile || [] }

        case auth.REGISTER_USER:

            return {
                ...state,
                auth: {
                    islogged: true,
                    error: false,
                    user: action.payload || [],
                    userData: JSON.parse(window.atob(payloads[1]) || []),
                    jwt: action.payload?.data?.token || ''
                }
            }


        case auth.ERROR_MSG:
            return { ...state, error: true, msg: action.payload || [] }

        case auth.RESET_STATE:
            return { ...state, error: false }

        case auth.LOGOUT:
            if (typeof window !== 'undefined') {
                window.localStorage.clear()
            }
            return {
                ...state,
                islogged: null,
                user: null,
                jwt: null,
                userData: null
            }

        /** Year */
        case year.GET_YEAR:
            return { ...state, year: action.payload || [] };

        case year.POST_YEAR:
            return { ...state, year: action.payload || [] };

        case year.PUT_YEAR:
            return { ...state, year: action.payload || [] };

        case year.DEL_YEAR:
            return { ...state, year: action.payload || [] };
        default:
            return state
    }
}

import * as api from '../../reducers/api/methods'

import { call, put, select } from 'redux-saga/effects'

export default function * getEffectSaga (action) {
  try {
    yield put({ type: action.flag }) // set status false

    const state = yield select()

    const jwt = state.auth.jwt

    const { data } = yield call(api.apiGet, action.path, jwt)

    const paginate = (arr, size) => {
      return arr.reduce((acc, val, i) => {
        const idx = Math.floor(i / size)
        const page = acc[idx] || (acc[idx] = [])
        page.push(val)

        return acc
      }, [])
    }

    yield put({ type: action.subtype, payload: paginate(data, action.size) })
  } catch (e) {
    window.alert(e)
  }
}

import { put, select } from 'redux-saga/effects'

import { converteData } from '../utils/converteData'

export default function * viewPeriodEffectSaga (action) {
  try {
    yield put({ type: action.flag }) // set status false

    const state = yield select()

    const old = state[action?.state][action?.intercept]

    const data = old.filter((result) => {
      console.log(result.dateprev)
      return (
        converteData(result.dateprev) >=
          converteData(action.payload.dataInicial) &&
        converteData(result.dateprev) <= converteData(action.payload.dataFinal)
      )
    })

    yield put({ type: action.subtype, payload: Array.from(data) })
  } catch (e) {
    alert(e)
  }
}

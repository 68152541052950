import React from 'react'

import loadable from '@loadable/component'

import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'

import { PersistGate } from 'redux-persist/integration/react'

import { store, persistor } from './state/createStore'

import './index.css'

const ProgressBar = loadable(() => import('react-materialize/lib/ProgressBar'))

const App = loadable(() => import('./App'))
// import App from "./App";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<ProgressBar />} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'

import { persistStore } from 'redux-persist'

import rootReducers from './combineReducers'

import { rootSaga } from './sagas'

const sagaMiddleware = createSagaMiddleware()

export const store = createStore(rootReducers, applyMiddleware(sagaMiddleware))

sagaMiddleware.run(rootSaga)

export const action = (type) => store.dispatch({ type })

export const persistor = persistStore(store)

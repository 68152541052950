import * as api from '../../reducers/api/methods'

import * as Utils from '../utils'

import { call, put, select } from 'redux-saga/effects'

export default function * deleteEffectSaga (action) {
  try {
    yield put({ type: action.flag }) // set status false

    const state = yield select()

    const jwt = state.auth.jwt

    const { data } = yield call(api.apiDelete, action.path, jwt)
    if (parseInt(data.code, 10) === 1) {
      const old = state[action?.state][action?.intercept]

      const result = Utils.remove(old, action.id)

      yield put({ type: action.subtype, payload: result })
    } else {
      return window.alert(data.msg)
    }
  } catch (e) {
    window.alert(e)
  }
}
